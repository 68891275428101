import { CartItemOptions, Maybe, ProductStockDataArgs, Scalars } from 'service/types';
import {
  useGlobalOrderTypeAndTime,
  usePersistedAppliedVoucherCode,
  usePersistedBranchId,
  usePersistedVoucherError,
} from 'common/hooks';
import { ORDER_TIME_TYPES } from 'common/constants';
import { ErrorCodes } from 'common/lib/errorHandler/enums';
import ErrorsFactory from 'common/lib/errorHandler/errors';
import { apiMutate } from '../../utils/mutation';
import { updateCartItemMutation } from '../../mutations/cart';
import ServiceConfigs from '../../config';
import { useUpdateCartValues } from './utils';
import { useVerifyVoucher } from '../voucher';

export type updateCartItemVarsType = {
  cartItemId: Scalars['String'];
  menuItemId: Scalars['String'];
  variantId: Scalars['ID'];
  variantQuantity: Scalars['Int'];
  options?: Maybe<Array<CartItemOptions>>;
  notes?: string;
  scheduledTime?: ProductStockDataArgs['scheduledTime'];
};

type UseUpdateCartItemType = ({
  cartId,
  isCartAuthorized,
}: {
  cartId: string;
  isCartAuthorized?: boolean;
}) => (variables: updateCartItemVarsType) => Promise<void | { hasError: boolean }>;

const useUpdateCartItem: UseUpdateCartItemType = ({ cartId, isCartAuthorized }) => {
  const updateCartValues = useUpdateCartValues({ cartId, isCartAuthorized });
  const verifyVoucher = useVerifyVoucher();
  const [, setVoucherErrorMessage] = usePersistedVoucherError();
  const [globalOrderTypeAndTime] = useGlobalOrderTypeAndTime();
  const [appliedVoucherCode, ,] = usePersistedAppliedVoucherCode();
  const { fulfillmentTimeType } = globalOrderTypeAndTime || {};
  const isScheduled = fulfillmentTimeType === ORDER_TIME_TYPES.SCHEDULE;
  const [persistedBranchId] = usePersistedBranchId();

  const verifyAndUpdateVoucher = async () => {
    const { valid, code } = await verifyVoucher(appliedVoucherCode, isScheduled, cartId);
    if (valid) {
      setVoucherErrorMessage('');
    } else {
      const errorDetails = ErrorsFactory.getError(code as ErrorCodes);
      setVoucherErrorMessage(`errors:${errorDetails?.errorDescription}`);
    }
  };
  const updateCartItem = async variables =>
    apiMutate({
      mutation: cartId ? updateCartItemMutation : null,
      variables: {
        subdomain: ServiceConfigs.getSubDomain(),
        cartId,
        ...(persistedBranchId ? { branchId: persistedBranchId } : {}),
        ...variables,
      },
      requestHeaders: isCartAuthorized ? { ...ServiceConfigs.getAuthorizationHeader() } : {},
      handler: (res: any) => res?.updateCartItem?.cart,
    }).then(async cart => {
      if (cart) {
        await updateCartValues(cart);
        if (appliedVoucherCode) {
          await verifyAndUpdateVoucher();
        }
      } else {
        await updateCartValues();
      }
      return { hasError: !cart };
    });

  return updateCartItem;
};

export default useUpdateCartItem;
